export const tableColumn = [
	{
		label: '部门',
		prop: 'deptName',
	},
	{
		label: '顾问',
		prop: 'username',
	},
	{
		label: '来访人员数量',
		prop: 'count',
		isCustomize: true,
		tableType: 'visitNum',
	},
]
export const visitTableColumn = [
	{
		label: '序号',
		prop: 'id',
		isCustomize: true,
		tableType: 'no',
	},
	{
		label: '客户姓名',
		prop: 'name',
	},
	{
		label: '客户电话',
		prop: 'mobile',
	},
	{
		label: '跟进人',
		prop: 'username',
	},
	{
		label: '部门',
		prop: 'deptName',
	},
	{
		label: '上门时间',
		prop: 'createTime',
		width: '180px',
	},
]
