<template>
  <div>
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @changeDept="changeDept"
      @submit="handleSubmit"
      @reset="handleReset"
    />
    <div style="margin-bottom: 15px; font-size: 14px;font-weight: 800; color: #606266">当前来访登记总数为{{ totalVisit }}人,请继续加油!</div>
    <CustomerVisitRegTable
      v-if="tableData"
      :tableColumn="tableColumn"
      :tableData="tableData"
      @visitNum="handleVisitNum"
    />
    <CustomerPagination
      :total="total"
      @currentChange="handleCurrentChange"
      @sizeChange="handleSizeChange"
    />
    <DialogTable
      width="800px"
      title="客户来访列表"
      :isShowDialog="isShowDialog"
      :tableColumn="visitTableColumn"
      :tableData="visitTableData"
      :pagination="visitPagination"
      @close="handleColse"
      :total="visitTotal"
      @current="currentChange"
      @sizeChange="sizeChange"
    />
  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { tableColumn, visitTableColumn } from "./index.js";
import CustomerVisitRegTable from "@/components/table";
import CustomerPagination from "@/components/pagination";
import { useStore } from "vuex";
import { getUserByDept } from "@/api/method/common";
import { reset } from "@/until";
import DialogTable from "@/components/dialog-table";

export default {
  name: "LoginLog",
  components: {
    FilterForm,
    CustomerVisitRegTable,
    CustomerPagination,
    DialogTable,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      deptId: null,
      userId: null,
      date: null,
    });
    const filters = reactive([
      [
        {
          label: "选择部门",
          type: "select",
          attr: "deptId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "80px",
        },
        {
          label: "选择顾问",
          type: "select",
          attr: "userId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "100px",
        },
        {
          label: "上门时间",
          type: "dateRange",
          attr: "date",
          column: 8,
          labelWidth: "80px",
          valueFormat: "YYYY-MM-DD",
        },
        {
          type: "button",
          column: 4,
        },
      ],
    ]);

    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const tableData = computed(
      () => state.customerVisitReg && state.customerVisitReg.records
    );
    const total = computed(
      () => state.customerVisitReg && state.customerVisitReg.total
    );
    const totalVisit = computed(() => state.customerVisitReg && state.customerVisitReg.totalCount);
    let currentParam;
    const getList = () => {
      const { date, ...param } = filterData;

      currentParam = {date,...param};
      const startTime = date && date[0];
      const endTime = date && date[1];
      dispatch("customerVisitReg", {
        ...pagination,
        ...param,
        startTime,
        endTime,
      });
    };

    const handleCurrentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const handleSizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const visitPagination = reactive({
      page: 1,
      size: 5,
    });

    const visitTableData = ref([]);
    const visitTotal = ref(0);

    onMounted(() => {
      getList();
      getDepartList();
    });

    const getDepartList = async () => {
      await dispatch("departmentList");
      filters[0][0].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filters[0][1].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };
    const isShowDialog = ref(false);
    const handleVisitNum = async (userId) => {
      const userInfo = computed(() => state.userInfo)
      console.log("2w2wwwqeqwe " + userInfo.value.deptId);
      if (userInfo.value.deptId !== 1){
        return;
      }
      isShowDialog.value = true;
      console.log(userId, 'userId--')
      getVisitList(userId);
    };

    const getVisitList = async (userId) => {
      const resetMarker = true;
      const { date } = currentParam;
      const startTime = date && date[0];
      const endTime = date && date[1];
      await dispatch("customerVisit", {
        userId,
        startTime,
        endTime,
        resetMarker,
        ...visitPagination,
      });
      visitTableData.value = state.customerVisit.records;
      visitTotal.value = state.customerVisit.total;
    };
    const handleColse = () => (isShowDialog.value = false);
    const currentChange = (page) => {
      visitPagination.page = page;
      getVisitList();
    };
    const sizeChange = (size) => {
      visitPagination.size = size;
      getVisitList();
    };

    return {
      filters,
      filterData,
      tableColumn,
      tableData,
      total,
      changeDept,
      handleSubmit,
      handleReset,
      handleVisitNum,
      visitTableColumn,
      visitPagination,
      visitTableData,
      handleColse,
      isShowDialog,
      visitTotal,
      totalVisit,
      currentChange,
      sizeChange,
      handleCurrentChange,
      handleSizeChange,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
