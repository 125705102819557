<template>
  <el-dialog
    :title="title"
    :width="width"
    v-model="showDialog"
    :before-close="handleClose"
  >
    <Table
      :tableColumn="tableColumn"
      :tableData="tableData"
      @visitNum="handleVisitNum"
      :page="pagination.page"
      :size="pagination.size"
    />
    <CustomerPagination
      :total="total"
      @current-change="currentChange"
      @sizeChange="sizeChange"
    />
  </el-dialog>
</template>

<script>
import { ElDialog, ElTable, ElTableColumn } from "element-plus";
import Table from "@/components/table";
import { computed } from "vue";
import CustomerPagination from "@/components/pagination";

export default {
  name: "DialogView",
  props: [
    "tableColumn",
    "tableData",
    "title",
    "isShowDialog",
    "width",
    "total",
    "pagination",
  ],
  components: {
    [ElDialog.name]: ElDialog,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    Table,
    CustomerPagination,
  },
  setup(props, context) {
    const showDialog = computed(() => props.isShowDialog);
    const handleClose = () => context.emit("close");
    const currentChange = () => context.emit("currentChange");
    const sizeChange = () => context.emit("sizeChange");

    return {
      showDialog,
      handleClose,
      currentChange,
      sizeChange,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/css/form";
</style>